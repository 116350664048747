import { render, staticRenderFns } from "./DeatilProductingCommodity.vue?vue&type=template&id=6fd838c3&scoped=true"
import script from "./DeatilProductingCommodity.vue?vue&type=script&lang=js"
export * from "./DeatilProductingCommodity.vue?vue&type=script&lang=js"
import style0 from "./DeatilProductingCommodity.vue?vue&type=style&index=0&id=6fd838c3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fd838c3",
  null
  
)

export default component.exports